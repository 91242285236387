<template>
    <div>

        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="customer_id">{{$t('daily_brief_report_invoices.customer')}}</label>
                                <multiselect v-model="customer"
                                    :placeholder="$t('daily_brief_report_invoices.customer')"
                                    label="fullname"
                                    track-by="id"
                                    :options="customers"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('daily_brief_report_invoices.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('daily_brief_report_invoices.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('daily_brief_report_invoices.added_by')}}</label>
                                <multiselect v-model="user"
                                    :placeholder="$t('daily_brief_report_invoices.added_by')"
                                    label="name"
                                    track-by="id"
                                    :options="users"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('daily_brief_report_invoices.collected_by')}}</label>
                                <multiselect v-model="collected_by"
                                    :placeholder="$t('daily_brief_report_invoices.collected_by')"
                                    label="name"
                                    track-by="id"
                                    :options="users"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="f_status">{{$t('payment_method')}}</label>
                                <select name="" id="f_status" v-model="filters.payment_method" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in payment_method_list" :value="row.id" :key="'payment_method'+index">{{ row.title }}</option>
                                </select>
                            </div>
                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <b-tabs content-class="mt-3">
                            <b-tab active :title="$t('daily_brief_report_invoices.summary')">

                                <div class="row mb-10">
                                    <div class="col-md-12 mt-10" id="summaryTableTitle">
                                        <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_payments')}}</h4>
                                        <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                    </div>
                                    <div class="col-md-12 mt-10" v-if="line_invoice">
                                        <LineChartPayment :data-line-payment="line_invoice" :currency-name="currency_name"/>
                                    </div>

                                </div>
                                <div class="row mb-10">
                                    <div class="col-12" v-if="data_report">
                                        <div class="table-responsive">
                                            <div  class="row justify-content-end p-4">
                                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                                    <download-excel
                                                            class="dropdown-item"
                                                            :fetch="dataReportFn"
                                                            :fields="json_fields_summary"
                                                            :name="$t('daily_brief_report_invoices.daily_brief_report_payments')+'.xls'">
                                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                                    </download-excel>

                                                    <button class="dropdown-item" @click="printData('summaryTable')">
                                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                    </button>

                                                    <!-- <div @click="exportReport('csv', 'revenue_invoice', $t('revenue_invoice'))" class="dropdown-item">
                                                        <i class="fa fa-file-o"></i>
                                                        Export CSV
                                                    </div> -->
                                                </b-dropdown>
                                            </div>
                                            <table id="summaryTable" class="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>{{$t('daily_brief_report_invoices.date')}}</th>
                                                    <!--                                            <th>{{$t('daily_brief_report_invoices.paid')}} ({{currency_name}})</th>-->
                                                    <!--                                            <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currency_name}})</th>-->
                                                    <!--                                            <th>{{$t('daily_brief_report_invoices.refund')}} ({{currency_name}})</th>-->
                                                    <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(row, index) in data_report" :key="'dataReport'+index">
                                                    <td>{{row.label}}</td>
                                                    <!--                                            <td>{{row.paid}}</td>-->
                                                    <!--                                            <td>{{row.unpaid}}</td>-->
                                                    <!--                                            <td>{{row.refund}}</td>-->
                                                    <td>{{row.total}}</td>
                                                </tr>

                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td>{{$t('daily_brief_report_invoices.total')}}</td>
                                                    <th>{{total_sum}}</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </b-tab>
                            <b-tab :title="$t('daily_brief_report_invoices.details')">

                                <div class="row mb-10">
                                    <div class="col-md-12 mt-10" id="DetailsTableTitle">
                                        <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_payments')}}</h4>
                                        <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                    </div>
                                    <div class="col-md-12 mt-10" v-if="line_invoice_details">
                                        <LineChartPayment :data-line-payment="line_invoice_details" :currency-name="currency_name"/>
                                    </div>
                                </div>
                                <div class="row mb-10">
                                    <div class="col-12" v-if="data_report_details">
                                        <div class="table-responsive">
                                            <div class="row justify-content-end p-4">
                                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

                                                    <download-excel
                                                            class="dropdown-item"
                                                            :fetch="salesInvoiceExport"
                                                            :fields="json_fields_sales_invoice"
                                                            :name="$t('daily_brief_report_invoices.daily_brief_report_payments')+'.xls'">
                                                        <i class="la la-file-excel"></i>{{ $t('excel')}}
                                                    </download-excel>
                                                    <button class="dropdown-item" @click="printData('DetailsTable')">
                                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                    </button>
                                                </b-dropdown>
                                            </div>
                                            <table class="table table-bordered" id="DetailsTable">
                                                <thead>
                                                <tr>
                                                    <th>{{$t('daily_brief_report_invoices.number')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.invoice_number')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.customer')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.employee')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.payment_method')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.ref_no')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-for="(row, index) in data_report_details">
                                                    <tr :key="'a'+index">
                                                        <td colspan="7">{{row.label}}</td>
                                                    </tr>
                                                    <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                                                        <td>{{row2.id}}</td>
                                                        <td>{{row2.invoice_code}}</td>
                                                        <td>{{row2.customer_name}}</td>
                                                        <td>{{row2.added_by}}</td>
                                                        <td>{{row2.payment_method_name}}</td>
                                                        <td>{{row2.ref_no}}</td>
                                                        <td>{{row2.total}}</td>
                                                    </tr>
                                                    <tr :key="'b'+index">
                                                        <td colspan="6">{{$t('daily_brief_report_invoices.total')}}</td>
                                                        <td>{{row.total.sum_total}}</td>
                                                    </tr>
                                                </template>

                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colspan="6"><b>{{$t('daily_brief_report_invoices.total')}}</b></td>
                                                    <th>{{details_total_sum}}</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                                        <option :value="null">{{$t('select_filter')}}</option>
                                        <option value="weekly">{{$t('weekly')}}</option>
                                        <option value="monthly">{{$t('monthly')}}</option>
                                        <option value="yearly">{{$t('yearly')}}</option>
                                        <option value="customer">{{$t('customer')}}</option>
                                        <option value="employee">{{$t('employee')}}</option>
                                        <option value="payment_method">{{$t('payment_method')}}</option>
                                        <option value="collected_by">{{$t('collected_by')}}</option>
                                    </select>
                                </template>
                                <div class="row mb-10">
                                    <div class="col-md-12 mt-10" id="DetailsTableByFilterTitle">
                                        <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_payments')}}</h4>
                                        <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                    </div>
                                    <div class="col-md-12 mt-10" v-if="line_invoice_details_by_filter">
                                        <BarChart :data-line-payment="line_invoice_details_by_filter" :currency-name="currency_name"/>
                                    </div>
                                </div>
                                <div class="row mb-1 d-flex justify-content-end p-4">
                                    <b-dropdown  right :text="$t('export')" class="m-5">
                                        <download-excel
                                                class="dropdown-item"
                                                :fetch="dataReportDetailsByFilterFn"
                                                :fields="json_fields_details_by_filter"
                                                :name="$t('daily_brief_report_invoices.daily_brief_report_payments')+'.xls'">
                                            <i class="la la-file-excel"></i>{{$t('excel')}}
                                        </download-excel>
                                        <button class="dropdown-item" @click="printData('DetailsTableByFilter')">
                                            <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                        </button>
                                    </b-dropdown>
                                    <div class="col-12" v-if="data_report_details_by_filter">
                                        <div class="table-responsive">
                                            <table class="table table-bordered" id="DetailsTableByFilter">
                                                <thead>
                                                <tr id="myRow">
                                                    <th>{{$t('daily_brief_report_invoices.number')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.invoice_number')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.date')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.customer')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.employee')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.payment_method')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.ref_no')}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-for="(row, index) in data_report_details_by_filter">
                                                    <tr :key="'c'+index">
                                                        <td colspan="8">{{row.label}}</td>
                                                    </tr>
                                                    <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                        <td>{{row2.id}}</td>
                                                        <td>{{row2.invoice_code}}</td>
                                                        <td>{{row2.payment_date}}</td>
                                                        <td>{{row2.customer_name}}</td>
                                                        <td>{{row2.added_by}}</td>
                                                        <td>{{row2.payment_method_name}}</td>
                                                        <td>{{row2.ref_no}}</td>
                                                        <td>{{row2.total}}</td>
                                                    </tr>
                                                    <tr :key="'d'+index">
                                                        <td colspan="7">{{$t('daily_brief_report_invoices.total')}}</td>
                                                        <td>{{row.total.sum_total}}</td>
                                                    </tr>
                                                </template>

                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colspan="7">{{$t('daily_brief_report_invoices.total')}}</td>
                                                    <th>{{details_total_sum_by_filter}}</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-10" v-if="filters.filter_by">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>{{getLabel()}}</th>
                                                    <th>{{$t('daily_brief_report_invoices.paid')}} ({{currency_name}})</th>
                                                    <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currency_name}})</th>
                                                    <th>{{$t('daily_brief_report_invoices.refund')}} ({{currency_name}})</th>
                                                    <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(row, index) in data_report_by_filter" :key="'dataReport'+index">
                                                    <td>{{row.label}}</td>
                                                    <td>{{row.paid}}</td>
                                                    <td>{{row.unpaid}}</td>
                                                    <td>{{row.refund}}</td>
                                                    <td>{{row.total}}</td>
                                                </tr>

                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colspan="4">{{$t('daily_brief_report_invoices.total')}}</td>
                                                    <th>{{data_report_total_sum_by_filter}}</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </b-tab>
                        </b-tabs>
                        <div class="col-12 text-center" v-if="page">
                            <button class="btn btn-warning" @click="loadMore">
                                <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import LineChartPayment from './components/LineChartPayment.vue'
    import BarChart from './components/BarChartPayment.vue'

    export default {
        name: "index-daily-brief-report-payments",
        components: {LineChartPayment, BarChart},
        data() {
            return {
                mainRoute: '/dashboard',
                mainRouteDependency: 'base/dependency',

                line_invoice: [],
                data_report: [],
                currency_name: null,
                total_sum: 0,

                line_invoice_details: [],
                data_report_details: [],
                details_total_sum: 0,

                line_invoice_details_by_filter: [],
                data_report_details_by_filter: [],
                details_total_sum_by_filter: 0,
                data_report_by_filter: [],
                data_report_total_sum_by_filter: 0,
                filter_by_url: this.$route.query.filter_by,

                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    user_id: null,
                    collected_user_id: null,
                    payment_method: null,
                    filter_by: null,
                },
                payment_method_list: [],
                data: [],
                customers: [],
                users: [],
                isDisabled: true,
                customer: null,
                user: null,
                collected_by: null,
                page : 0,

            }
        },
        watch: {
            'filters.filter_by': function (val) {
                if (val) {
                    this.getLineChartInvoiceDetailsByFilter();
                    this.getReportInvoiceDetailsByFilter();
                    this.getReportByFilter();
                }
            },
            customer: function (val) {
                if (val && val.id) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            user: function (val) {
                if (val && val.id) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            collected_by: function (val) {
                if (val && val.id) {
                    this.filters.collected_by_id = val.id;
                } else {
                    this.filters.collected_by_id = null;
                }
            }
        },
        computed: {

            json_fields_summary: function () {
                let fields = {};
                fields[this.$t('revenue_invoices.date')] = 'label';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },
            json_fields_details_by_filter: function () {
                let fields = {};

                switch (this.filters.filter_by) {

                    case 'weekly':
                    case 'monthly':
                    case 'yearly':
                    case 'customer':
                        fields[this.$t('revenue_invoices.id')] = 'label';
                        fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                        fields[this.$t('revenue_invoices.date')] = 'payment_date';
                        fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                        fields[this.$t('revenue_invoices.employee')] = 'added_by';
                        fields[this.$t('revenue_invoices.payment_method')] = 'payment_method_name';
                        fields[this.$t('revenue_invoices.ref_no')] = 'ref_no';
                        fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                        break;
                    case 'employee':
                    case 'payment_method':
                        fields[this.$t('revenue_invoices.id')] = 'label';
                        fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                        break;
                    case 'collected_by':
                        fields[this.$t('revenue_invoices.id')] = 'label';
                        fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                        fields[this.$t('revenue_invoices.date')] = 'payment_date';
                        fields[this.$t('revenue_invoices.employee')] = 'added_by';
                        fields[this.$t('revenue_invoices.payment_method')] = 'payment_method_name';
                        fields[this.$t('revenue_invoices.ref_no')] = 'ref_no';
                        fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                        break;
                }
                // fields[this.$t('revenue_invoices.id')] = 'label';
                // fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                // fields[this.$t('revenue_invoices.date')] = 'payment_date';
                // fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                // fields[this.$t('revenue_invoices.employee')] = 'added_by';
                // fields[this.$t('revenue_invoices.payment_method')] = 'payment_method_name';
                // fields[this.$t('revenue_invoices.ref_no')] = 'ref_no';
                // fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },


            // *****************************************---
            json_fields: function () {
                let fields = {};
                fields[this.$t('revenue_invoices.id')] = 'label';
                fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                fields[this.$t('revenue_invoices.employee')] = 'added_by';
                fields[this.$t('revenue_invoices.payment_method')] = 'payment_method_name';
                fields[this.$t('revenue_invoices.ref_no')] = 'ref_no';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                return fields;
            },
            json_fields_sales_invoice: function () {
                let fields = {};

                fields[this.$t('revenue_invoices.id')] = 'label';
                fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                fields[this.$t('revenue_invoices.employee')] = 'added_by';
                fields[this.$t('revenue_invoices.payment_method')] = 'payment_method_name';
                fields[this.$t('revenue_invoices.ref_no')] = 'ref_no';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                return fields;
            },
        },
        
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.daily_brief_report_payments")}]);

            this.getCustomers();
            this.getUsers();
            this.getPaymentMethodList();
            this.getAuthUser();


            if (this.filter_by_url) {
                this.filters.filter_by = this.filter_by_url;
                this.doFilter();
            }
        },
        methods: {
            dataReportFn() {
                let _footer = {label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`, total: this.total_sum};
                let _data = [...this.data_report];
                _data.push(_footer);
                return _data;
            },
            dataReportDetailsByFilterFn() {
                this.setReportLog('excel', 'daily brief report payments');
                let _footer = {label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`, total: this.details_total_sum_by_filter};
                let _data = [];

                if (this.filters.filter_by == 'employee' || this.filters.filter_by == 'payment_method') {
                    this.data_report_details_by_filter.forEach((row) => {
                        _data.push({
                            label: row.label,
                            total: row.total.sum_total,
                        });
                    });
                } else {
                    this.data_report_details_by_filter.forEach((row) => {
                        _data.push({
                            label: row.label,
                            invoice_code: '',
                            payment_date: '',
                            customer_name: '',
                            added_by: '',
                            payment_method_name: '',
                            ref_no: '',
                            total: '',
                        });
                        row.details.forEach((sub_row) => {
                            _data.push({
                                label: sub_row.id,
                                invoice_code: sub_row.invoice_code,
                                payment_date: sub_row.payment_date,
                                customer_name: sub_row.customer_name,
                                added_by: sub_row.added_by,
                                payment_method_name: sub_row.payment_method_name,
                                ref_no: sub_row.ref_no,
                                total: sub_row.total,
                            });
                        });
                        _data.push({
                            label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`,
                            invoice_code: '',
                            payment_date: '',
                            customer_name: '',
                            added_by: '',
                            payment_method_name: '',
                            ref_no: '',
                            total: row.total.sum_total,
                        });
                    });
                }

                _data.push(_footer);
                return _data;
            },

            // *****************************************---

            salesInvoiceExport() {
                this.setReportLog('excel', 'daily brief report payments');
                let _footer = {label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`, total: this.details_total_sum};
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        label: row.label,
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_method_name: '',
                        ref_no: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.id,
                            invoice_code: sub_row.invoice_code,
                            customer_name: sub_row.customer_name,
                            added_by: sub_row.added_by,
                            payment_method_name: sub_row.payment_method_name,
                            ref_no: sub_row.ref_no,
                            total: sub_row.total,
                        });
                    });
                    _data.push({
                        label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`,
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_method_name: '',
                        ref_no: '',
                        total: row.total.sum_total,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },


            doFilter() {
                this.page = 1;
                this.getLineChartInvoiceSummary();
                this.getReportInvoiceSummary();

                this.getLineChartInvoiceDetails();
                this.getReportInvoiceDetails();

                if (this.filters.filter_by) {
                    this.getLineChartInvoiceDetailsByFilter();
                    this.getReportInvoiceDetailsByFilter();
                    this.getReportByFilter();
                }
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.user_id = null;
                this.filters.collected_user_id = null;
                this.filters.payment_method = null;
                this.filters.filter_by = null;
                this.customer = null;
                this.suserpplier = null,
                this.collected_by = null,

                this.doFilter();
            },
            loadMore() {
                this.page = 1;
                this.getLineChartInvoiceSummary();
                this.getReportInvoiceSummary();

                this.getLineChartInvoiceDetails();
                this.getReportInvoiceDetails();

                if (this.filters.filter_by) {
                    this.getLineChartInvoiceDetailsByFilter();
                    this.getReportInvoiceDetailsByFilter();
                    this.getReportByFilter();
                }
            },
            getPaymentMethodList() {
                ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
                    this.payment_method_list = response.data.data;
                });
            },

            getCustomers() {
                ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
                    this.customers = response.data.data;
                });
            },
            getUsers() {
                ApiService.get(this.mainRouteDependency + "/users").then((response) => {
                    this.users = response.data.data;
                });
            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            /*****
             * summary tab action
             */
            getLineChartInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/payment/report_payment`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.line_invoice = response.data;
                });
            },
            getReportInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/payment/report_payment_list`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data_report = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total ? parseFloat(row.total) : 0;
                    });
                    this.total_sum = _total_sum.toFixed(2);
                });
            },

            /*****
             * details tab action
             */
            getLineChartInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/payment/report_payment`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.line_invoice_details = response.data;
                });
            },
            getReportInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/payment/report_payment_details`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data_report_details = response.data;
                    let _total_sum = this.$_.sumBy(response.data, (row) => {
                        return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
                    });
                    this.details_total_sum = _total_sum.toFixed(2);
                    this.isDisabled = false;
                });
            },


            /*****
             * details by filter tab action
             */
            getLineChartInvoiceDetailsByFilter() {
                ApiService.get(`${this.mainRoute}/payment/report_payment_by_filter`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.line_invoice_details_by_filter = response.data;
                });
            },
            getReportInvoiceDetailsByFilter() {
                ApiService.get(`${this.mainRoute}/payment/report_payment_details_by_filter`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data_report_details_by_filter = response.data;
                    let _total_sum = this.$_.sumBy(response.data, (row) => {
                        return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
                    });
                    this.details_total_sum_by_filter = _total_sum.toFixed(2);
                });
            },
            getReportByFilter() {
                ApiService.get(`${this.mainRoute}/payment/report_payment_paid_unpaid/list`, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data_report_by_filter = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total ? parseFloat(row.total) : 0;
                    });
                    this.data_report_total_sum_by_filter = _total_sum.toFixed(2);
                });
            },

            printData(tableId) {
                this.setReportLog('pdf', 'daily brief report payments');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            getLabel() {
                if (this.filters.filter_by == 'weekly' || this.filters.filter_by == 'monthly' || this.filters.filter_by == 'yearly') {
                    return this.$t('daily_brief_report_invoices.date');
                } else {
                    return this.$t(this.filters.filter_by);
                }
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
