<template>
  <div>
    <h4 class="mb-5">{{ $t("payments_summary") }} ({{ currencyName }})</h4>
    <BarChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar as BarChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: {
    BarChartGenerator,
  },
  props: {
    dataLinePayment: null,
    currencyName: null,
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dataLinePayment: function (val) {
      if(val){
        this.chartData.labels = val.labels;
        this.chartData.datasets[0].data = val.total;
      }
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t('total'),
            backgroundColor: "#2980b9",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.chartData.labels = this.dataLinePayment?this.dataLinePayment.labels:[];
    this.chartData.datasets[0].data = this.dataLinePayment?this.dataLinePayment.total:[];
  },
};
</script>
